import React, { useEffect } from "react";
import successfulLogo from "../../assets/successful.gif";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { restCartItems } from "../../redux/cart/actions";

const SucceededPage = ({restCartItems}) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      restCartItems();
      navigate("/");
    }, [2000]);
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <img src={successfulLogo} alt="Success" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDipatchToProps = {
  restCartItems,
};

export default connect(mapStateToProps, mapDipatchToProps)(SucceededPage);
