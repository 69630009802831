import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import NavBar from "../../components/nav-bar/navBar";
import "./category.css";
import ProductCard from "../../components/productCard/productCard";
import Footer from "../../components/footer/footer";
import axios from "axios";
import { config } from "../../config";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/button/primaryButton";
import SecondaryButton from "../../components/button/secondaryButton";
import NavBar2 from "../../components/nav-bar2/navBar2";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const Category = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { id: categoryId } = useParams(); // renamed id to categoryId to avoid confusion
  const [id, setId] = useState(categoryId);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState();
  const [statistics, setStatistics] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 9;
  const accessToken = Cookies.get("jwt");
  let user = null;

  if (accessToken) {
    try {
      user = jwtDecode(accessToken);
    } catch (error) {
      console.error("Invalid token:", error);
    }
  } else {
    console.log("No token found");
  }

  const handleShowMore = (page) => {
    setPage(page + 1);
    axios
      .get(
        config.baseURL +
          `Products/getProducts?id=${id}&page=${page + 1}&pageSize=${pageSize}`
      )
      .then((response) => {
        setProducts((prevProducst) => [...prevProducst, ...response.data]);
        if (response.data.length < pageSize) {
          setHasMore(false);
        }
      });
  };

  useEffect(() => {
    axios
      .get(
        config.baseURL +
          `Products/getProducts?id=${id}&page=${page}&pageSize=${pageSize}`
      )
      .then((response) => {
        setProducts([]);
        setProducts((prevProducst) => [...prevProducst, ...response.data]);
        if (response.data.length < pageSize) {
          setHasMore(false);
        }
      });

    axios
      .get(config.baseURL + `Categories/getCategory?id=${id}`)
      .then((response) => {
        setCategory(response.data);
      });

    axios.get(config.baseURL + `Products/getProductsCount`).then((response) => {
      setStatistics(response.data);
    });
  }, [id]);

  function handleCategoryClick(statistic) {
    setCategory();
    setProducts([]);
    setPage(1);
    setId(statistic.category_ID);
    setHasMore(true);

    axios
      .get(
        config.baseURL +
          `Products/getProducts?id=${statistic.category_ID}&page=${1}&pageSize=${pageSize}`
      )
      .then((response) => {
        console.log(response.data);
        setProducts((prevProducst) => [...prevProducst, ...response.data]);
        if (response.data.length < pageSize) {
          setHasMore(false);
        }
      });

    axios
      .get(
        config.baseURL + `Categories/getCategory?id=${statistic.category_ID}`
      )
      .then((response) => {
        setCategory(response.data);
      });
  }

  return (
    <div className="category_page">
      <NavBar2 />

      <div className="category_page_content">
        <div className="categoryPage_left-side">
          <h2 className="category_page__header text-xl font-bold">
            {category ? (
              i18n.language === "en" ? (
                category.name_en
              ) : (
                category.name
              )
            ) : (
              <div style={{ textAlign: "center" }}>
                <CircularProgress />
              </div>
            )}
          </h2>
          {/* 
            {category ? (
              <p className="leftSide_paragraph">
                {t("categoryPage_categoryParagraph")}
              </p>
            ) : (
              <div style={{ textAlign: "center" }}>
                <CircularProgress />
              </div>
            )} */}

          <div className="productsCard_container">
            {products.length ? (
              products.map((product) => (
                <div className="productCard__">
                  <ProductCard
                    name={product.name}
                    price={
                      user
                        ? +user.userType === 1
                          ? product.price
                          : product.marketer_price
                        : product.price
                    }
                    image={product.image}
                    image_type={product.image_type}
                    id={product.internal_code}
                  />
                </div>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>
                <CircularProgress />
              </div>
            )}
          </div>
          {hasMore && (
            <div className="showMoreButton_container">
              <SecondaryButton
                text="Show More"
                handleClick={() => handleShowMore(page)}
              />
            </div>
          )}
        </div>

        <div className="categoryPage_right-side">
          <h3 className="rightSide_header">الاقسام</h3>
          {statistics.length ? (
            statistics.map((statistic) => (
              <div
                className="rightSide_category"
                onClick={() => handleCategoryClick(statistic)}
              >
                <div className="rightSide_category-number">
                  {statistic.count}
                </div>
                <div className="rightSide_category-name">
                  {i18n.language === "en" ? statistic.name_en : statistic.name}
                </div>
              </div>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          )}
          {/* <div className="rightSide_category">
              <div className="rightSide_category-number">232</div>
              <div className="rightSide_category-name">ركن</div>
            </div>

            <div className="rightSide_category">
              <div className="rightSide_category-number">150</div>
              <div className="rightSide_category-name">كنب</div>
            </div>

            <div className="rightSide_category">
              <div className="rightSide_category-number">50</div>
              <div className="rightSide_category-name">شيزلونج</div>
            </div>

            <div className="rightSide_category">
              <div className="rightSide_category-number">70</div>
              <div className="rightSide_category-name">جزامات</div>
            </div>

            <div className="rightSide_category">
              <div className="rightSide_category-number">85</div>
              <div className="rightSide_category-name">مكتبات</div>
            </div>

            <div className="rightSide_category">
              <div className="rightSide_category-number">120</div>
              <div className="rightSide_category-name">باف</div>
            </div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Category;
