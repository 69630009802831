import React, { useEffect, useState } from "react";
import NavBar from "../../components/nav-bar/navBar";
import Header from "../../components/header/header";
import Offers from "../../components/offers/offers";
import Categories from "../../components/categories/categories";
import Products from "../../components/products/products";
import Advantages from "../../components/advantages/advantages";
import NewProducts from "../../components/newProducts/newProducts";
import Footer from "../../components/footer/footer";
import Reviews from "../../components/reviews/reviews";
import axios from "axios";
import { config } from "../../config";
import CategoryProducts from "../../components/categoryProducts/categoryProducts";
import Companies from "../../components/companies/companies";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import Navbar2 from "../../components/nav-bar2/navBar2";
import Banner from "../../components/banner/banner";

const fetchCategories = async () => {
  const response = await axios.get(config.baseURL + "Categories/getCategories");
  return response.data.map((category) => ({
    ...category,
    image: `data:${category.image_type};base64,${category.image}`,
  }));
};

const fetchProductsForCategory = async (categoryId) => {
  const response = await axios.get(
    config.baseURL + `Products/getProductsForHomePage?id=${categoryId}`
  );
  return response.data;
};
const HomePage = () => {
  // const [categories, setCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  // const [products, setProducts] = useState([]);

  // function handleCategoryClick(category) {
  //   setProducts([]);
  //   setSelectedCategory(category.name);

  //   axios
  //     .get(config.baseURL + `Products/getProductsForHomePage?id=${category.id}`)
  //     .then((response) => {
  //       setProducts(response.data);
  //     });
  // }

  // useEffect(() => {
  //   setCategories([]);

  //   axios.get(config.baseURL + "Categories/getCategories").then((response) => {
  //     const categoriesWithImages = response.data.map((category) => ({
  //       ...category,
  //       image: `data:${category.image_type};base64,${category.image}`,
  //     }));

  //     handleCategoryClick(categoriesWithImages[0]);
  //     setSelectedCategory(categoriesWithImages[0].name);
  //     setCategories(categoriesWithImages);
  //   });
  //   // Update categories with base64-encoded image data
  // }, []);

  const [selectedCategory, setSelectedCategory] = useState(null);

  // Use React Query to fetch and cache categories
  const { data: categories, isLoading: categoriesLoading } = useQuery(
    "categories",
    fetchCategories,
    {
      staleTime: 1000 * 60 * 5, // Cache data for 5 minutes
    }
  );

  // Fetch products for the selected category
  const { data: products, isLoading: productsLoading } = useQuery(
    ["products", selectedCategory?.id],
    () => fetchProductsForCategory(selectedCategory?.id),
    {
      enabled: !!selectedCategory, // Fetch products only when a category is selected
    }
  );

  useEffect(() => {
    if (categories && categories.length > 0) {
      setSelectedCategory(categories[0]); // Set the first category as default
    }
  }, [categories]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  // if (categoriesLoading || productsLoading) {
  //   return <div>Loading...</div>; // Show a loading state while data is fetching
  // }

  return (
    <div>
      <Helmet>
        <title>عبدالرحمن ستورز - Adbelrhman Stores</title>
        <meta
          name="description"
          content="Welcome to عبدالرحمن ستورز, the best place for shopping..."
        />
        <meta name="keywords" content="عبدالرحمن, stores, عبدالرحمن ستورز" />
      </Helmet>
      <Navbar2 />
      <Banner categories={categories ? categories : []} />

      <div className="body">
        <Categories categoriesProp={categories ? categories : []} />
        <Header />
        {/* <Offers /> */}
        <Products
          categories={categories ? categories : []}
          products={products ? products : []}
          selectedCategory={selectedCategory}
          // setProducts={setProducts}
          setSelectedCategory={selectedCategory}
          handleCategoryClick={handleCategoryClick}
        />
        {/* {categories.map((category) => (
          <div key={category.id} className="category-section">
            <CategoryProducts category={category} />
          </div>
        ))} */}
        <NewProducts />
        <Reviews />
        {/* <Advantages /> */}
      </div>
      <Companies />
      <Footer />
    </div>
  );
};

export default HomePage;
