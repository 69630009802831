import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "../../assets/warshtyLogo.png"; // Import your logo image
import cairoRegular from "../../fonts/Cairo-Bold.ttf"; // Import your Cairo font as a TTF file
import jsPDF from "jspdf";
// Register Cairo font
Font.register({ family: "Cairo", src: cairoRegular });

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  logo: {
    width: 100,
    height: 50,
    marginBottom: 10,
  },
  text: {
    fontFamily: "Cairo",
    fontSize: 12,
    direction: "rtl",
    textAlign: "right",
    unicodeBidi: "embed",
    writingMode: "horizontal-tb",
  },
  headerText: {
    fontFamily: "Cairo",
    fontSize: 20,
  },
});

// Create Invoice component
const Invoice = ({ items }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image style={styles.logo} src={logo} /> {/* Add logo to the invoice */}
        <Text style={styles.headerText}>Invoice</Text>
        <Text style={styles.text}>Invoice Number: 123456</Text>
        <Text style={styles.text}>Date: {new Date().toLocaleDateString()}</Text>
        <table>
          <thead>
            <tr>
              <th style={styles.text}>Item</th>
              <th style={styles.text}>Quantity</th>
              <th style={styles.text}>Price</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td style={styles.text}>{item.name}</td>
                <td style={styles.text}>{item.quantity}</td>
                <td style={styles.text}>${item.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </View>
    </Page>
  </Document>
);

// Create PDF component
const PdfComponent = () => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const [fontBase64, setFontBase64] = useState("");

  const items = [
    { name: "العنصر ١", quantity: 2, price: 50 },
    { name: "العنصر ٢", quantity: 1, price: 30 },
    { name: "العنصر ٣", quantity: 3, price: 20 },
  ];

  useEffect(() => {
    const convertTTFtoBase64_ = async (filePath) => {
      try {
        const response = await fetch(filePath);
        const fontBlob = await response.blob();
        const reader = new FileReader();
        reader.onload = () => {
          const base64Font = reader.result.split(",")[1];
          setFontBase64(base64Font);
          setFontLoaded(true);
        };
        reader.readAsDataURL(fontBlob);
      } catch (error) {
        console.error("Error converting font to base64:", error);
      }
    };

    convertTTFtoBase64_(require('../../fonts/Amiri.ttf'));
  }, []);
  
  const savePDF = () => {
    if (!fontLoaded) {
      console.error("Font not loaded");
      return;
    }else{
      console.log("font loaded")
    }

    const doc = new jsPDF();

    // Add font
    doc.addFileToVFS("Cairo-Regular.ttf", fontBase64);
    doc.addFont("Cairo-Regular.ttf", "Cairo", "normal");
    doc.setFont("Cairo");

    // Add logo
    const imgData = logo;
    doc.addImage(imgData, "PNG", 10, 10, 100, 50);

    // Add header
    doc.setFontSize(20);
    doc.setR2L(false);
    doc.text("الفاتورة", 180, 70, null, null, "right");

    // Add invoice details
    doc.setFontSize(12);
    doc.text("رقم الفاتورة: 123456", 180, 80, { align: "right" });

    doc.text(`التاريخ: ${new Date().toLocaleDateString()}`, 180, 90, {
      align: "right",
    });

    doc.text("اسم العميل: محمود سليم محمود", 180, 100, { align: "right" });

    doc.text("العنوان : 14 شارع مشروع عثمان بهتيم شبرا الخيمة", 180, 110, {align: "right"})

  
    const tablePosY = 120; // Initial Y position of the table
    const tableHeight = doc.autoTable.previous.finalY - tablePosY;
    // Add table
    doc.autoTable({
      startY: tablePosY,
      head: [["المنتج", "الكمية", "السعر"]],
      body: items.map((item) => [item.name, item.quantity, `$${item.price}`]),
      styles: {
        font: "Cairo",
        cell: { textAlign: "right", width: "auto" }, // Set width to 'auto'
        head: { textAlign: "right", width: "auto" }, // Set width to 'auto'
      },
    });

    const textPosY = tablePosY + tableHeight + 10; 

    doc.text("الاجمالى قبل الشحن: 1200 جنيها", 180, textPosY, { align: "right" });
    doc.text("مصاريف الشحن: 500 جنيها", 180, textPosY + 10, { align: "right" });
    doc.text("الاجمالى : 1700 جنيها", 180, textPosY + 20, { align: "right" });
    doc.text("الدفعة المقدمة : 700 جنيها", 180, textPosY + 30, { align: "right" });
    doc.text("المطلوب تحصيله : 1000 جنيها", 180, textPosY + 40, { align: "right" });
    doc.text("أقر بأنى قمت بفحص المنتج وأنه خال من العيوب ومطابق للمواصفات", 180, textPosY + 50, { align: "right" });
    doc.text("تم دفع المبلغ ومراجعة المنتج", 180, textPosY + 60, { align: "right" });
    doc.text("توقيع العميل:", 180, textPosY + 70, { align: "right" });
    doc.text("توقيع المندوب:", 120, textPosY + 70, { align: "right" });

    doc.save("invoice.pdf");
  };

  return (
    <div>
      <button onClick={savePDF}>Download PDF</button>
      <PDFViewer width="1000" height="600">
        <Invoice items={items} />
      </PDFViewer>
    </div>
  );
};

export default PdfComponent;
