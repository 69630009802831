import React, { useContext, useEffect, useState } from "react";
import "./navBar2.css"; // Import your custom styles
import Logo from "../../assets/Abdo_Logo.png";
import Badge from "@material-ui/core/Badge";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { connect } from "react-redux";
import { fetchProductImages, removeFromCart } from "../../redux/cart/actions";
import { UpdateImageContext } from "../../context/updateImage";
import Drawer from "@mui/material/Drawer";
import ClearIcon from "@mui/icons-material/Clear";
import { CalculateTotal } from "../../utilities/calculateTotal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CartButton from "../button/cartButton";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@material-ui/core";
import Box from "@mui/material/Box";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import Divider from "@mui/material/Divider";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
const Navbar2 = ({ cart, removeFromCart, fetchProductImages }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isArabic, setIsArabic] = useState(true);
  const [drawerState, setDrawerState] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const { updateImage, setUpdateImage } = useContext(UpdateImageContext);
  const [drawerState2, setDrawerState2] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(
    i18n.language === "en" ? "en" : "ar"
  );
  const accessToken = Cookies.get("jwt");
  let user = null;

  if (accessToken) {
    try {
      user = jwtDecode(accessToken);
    } catch (error) {
      console.error("Invalid token:", error);
    }
  } else {
    console.log("No token found");
  }

  const isMobile = useMediaQuery("(max-width:768px)");
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState(!drawerState);
  };

  const handleLangugeChange = (newAlignment) => {
    setLanguage(newAlignment);
    i18n.changeLanguage(newAlignment);
    setIsArabic(!isArabic);
  };

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const toggleDrawer2 = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState2(!drawerState2);
  };
  useEffect(() => {
    // Update the cart count whenever the cart changes
    setItemCount(cart.length);
  }, [cart]);

  useEffect(() => {
    const productIds = cart.map((item) => item.id);

    if (productIds.length > 0) {
      // Fetch product images when the cart is opened
      fetchProductImages(productIds);
    }
  }, [updateImage]);

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        backgroundColor: "black",
        height: "100%",
        color: "white",
      }}
      role="presentation"
      onClick={toggleDrawer2(anchor, false)}
      onKeyDown={toggleDrawer2(anchor, false)}
    >
      <div className="divider2Content">
        <div
          className="dividerItem_content"
          onClick={() => {
            navigate("/");
          }}
        >
          <div className="divider_icon">
            <HomeIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="divider_text">{t("nav_bar__home")}</div>
        </div>
        <Divider style={{ backgroundColor: "grey" }} />
        <div className="dividerItem_content">
          <div className="divider_icon">
            <LocalOfferIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="divider_text">{t("nav_bar__offers")}</div>
        </div>
        <Divider style={{ backgroundColor: "grey" }} />
        <div className="dividerItem_content">
          <div className="divider_icon">
            <InfoIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="divider_text">{t("nav_bar__AboutUs")}</div>
        </div>
        <Divider style={{ backgroundColor: "grey" }} />
        <div
          className="dividerItem_content"
          onClick={() => {
            navigate("/signIn");
          }}
        >
          <div className="divider_icon">
            <HelpIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="divider_text">{t("nav_bar__marketer")}</div>
        </div>
        <Divider style={{ backgroundColor: "grey" }} />

        <div
          className="dividerItem_content"
          onClick={() => {
            if (user) {
              navigate("/myProfile");
            } else {
              navigate("/signIn");
            }
          }}
        >
          <div className="divider_icon">
            <AccountBoxIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="divider_text">{t("nav_bar__profile")}</div>
        </div>
        <Divider style={{ backgroundColor: "grey" }} />

        {user ? (
          <div>
            {" "}
            <div
              className="dividerItem_content"
              onClick={() => {
                Cookies.remove("jwt")
                if (location.pathname === "/") {
                  // Reload the page if already on the home page
                  window.location.reload();
                } else {
                  // Navigate to the home page
                  navigate("/");
                }
              }}
            >
              <div className="divider_icon">
                <AccountBoxIcon sx={{ fontSize: "2rem" }} />
              </div>
              <div className="divider_text">{t("nav_bar__signout")}</div>
            </div>
            <Divider style={{ backgroundColor: "grey" }} />{" "}
          </div>
        ) : null}

        <div className="language-toggle mt-3">
          <button
            className={`language-button ${language === "ar" ? "active" : ""}`}
            onClick={() => handleLangugeChange("ar")}
          >
            AR
          </button>
          <button
            className={`language-button ${language === "en" ? "active" : ""}`}
            onClick={() => handleLangugeChange("en")}
          >
            EN
          </button>
        </div>

        {/* <label class="language-switcher">
          <input type="checkbox" />
          <span class="slider round"></span>
          <span class="select-fr">FR</span>
          <span class="select-en">EN</span>
        </label> */}
      </div>
    </Box>
  );

  return (
    <header className="navbar" dir="rtl">
      <div className="navbar-container">
        {/* Logo */}
        <div
          className="navbar-logo"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={Logo} alt="Logo" className="logo" />
        </div>

        {/* Navigation Links for Desktop */}
        <nav className={`navbar-links ${isMobileMenuOpen ? "active" : ""}`}>
          <ul>
            <li>
              <Link to="/" style={{ textDecoration: "none" }}>
                <div>{t("nav_bar__home")}</div>
              </Link>
            </li>
            <li>
              <Link to="/" style={{ textDecoration: "none" }}>
                <div>{t("nav_bar__offers")}</div>
              </Link>
            </li>
            <li>
              <Link to="/" style={{ textDecoration: "none" }}>
                <div>{t("nav_bar__AboutUs")}</div>
              </Link>
            </li>
            <li>
              <Link to="/signIn" style={{ textDecoration: "none" }}>
                <div>{t("nav_bar__marketer")}</div>
              </Link>
            </li>
          </ul>
        </nav>

        {/* Shop Now Button, Cart Icon, and Language Toggle */}
        <div className="navbar-actions">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDrawerState(true);
            }}
          >
            <Badge color="secondary" badgeContent={itemCount}>
              <ShoppingCartIcon
                style={{
                  width: isMobile ? "30px" : "35px",
                  height: isMobile ? "30px" : "35px",
                }}
              />
            </Badge>
          </div>

          <button className="shop-now-button">تسوق الآن</button>

          {/* Language Toggle Button */}
          <div className="language-toggle">
            <button
              className={`language-button ${language === "ar" ? "active" : ""}`}
              onClick={() => handleLangugeChange("ar")}
            >
              AR
            </button>
            <button
              className={`language-button ${language === "en" ? "active" : ""}`}
              onClick={() => handleLangugeChange("en")}
            >
              EN
            </button>
          </div>

          <button
            className="menu-button"
            onClick={() => {
              setDrawerState2(true);
            }}
          >
            ☰
          </button>
        </div>
      </div>

      <Drawer
        anchor="left"
        open={drawerState}
        onClose={toggleDrawer("left", false)}
        PaperProps={{
          sx: {
            width: "20%",
            "@media screen and (max-width: 767px)": {
              width: "65%", // Styles for smaller screens
            },
            backgroundColor: "black",
            color: "white",
          },
        }}
      >
        <div className="drawer-content">
          <h2 className="cart-header">سلة التسوق</h2>

          {cart
            ? cart.map((item) => (
                <div className="product-item_container">
                  <div className="product-item">
                    <img
                      src={`data:${item.imageType};base64,${item.imageUrl}`}
                      alt="Product 1"
                    />
                    <div className="product-details">
                      <h3 className="product-name">{item.name}</h3>
                      {Object.entries(item.descriptions).map(
                        ([id, descriptions]) => (
                          <div style={{ fontWeight: "bold" }}>
                            {descriptions.title}: {descriptions.value}
                          </div>
                        )
                      )}
                      <p className="product-price">
                        السعر :{" "}
                        {String(item.finalPrice).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}{" "}
                        EGP
                      </p>
                    </div>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => removeFromCart(item.id)}
                  >
                    <ClearIcon />
                  </div>
                </div>
              ))
            : null}
        </div>

        <div className="total-section">
          <div className="total">
            <div className="total-price">
              {String(CalculateTotal(cart)).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}{" "}
              EGP
            </div>
            <div dir="rtl" className="total-text">
              المجموع :
            </div>
          </div>
          <div>
            <Link to={`/checkout`}>
              <CartButton />
            </Link>
          </div>
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={drawerState2}
        onClose={toggleDrawer2("right", false)}
      >
        {list("right")}
      </Drawer>
    </header>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = {
  removeFromCart,
  fetchProductImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar2);
