import React, { useEffect, useState } from "react";
import "./search.css";
import NavBar from "../../components/nav-bar/navBar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { config } from "../../config";
import { CircularProgress } from "@mui/material";
import ProductCard from "../../components/productCard/productCard";
import NavBar2 from "../../components/nav-bar2/navBar2";
import Footer from "../../components/footer/footer";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const SearchPage = () => {
  let { category, productName } = useParams();
  const [products, setProducts] = useState([]);
  const accessToken = Cookies.get("jwt");
  let user = null;

  if (accessToken) {
    try {
      user = jwtDecode(accessToken);
    } catch (error) {
      console.error("Invalid token:", error);
    }
  } else {
    console.log("No token found");
  }
  
  useEffect(() => {
    setProducts([]);
    axios
      .get(config.baseURL + "Products/getProductByCategoryName", {
        params: {
          productName: productName,
          Category: category,
        },
      })
      .then((res) => {
        setProducts(res.data);
      });
  }, [category, productName]);
  return (
    <div>
      <NavBar2 />

      <div className="search-page">
        {products.length ? (
          <div className="productsCard_container">
            {products.map((product) => (
              <div className="productCard__">
                <ProductCard
                  name={product.name}
                  price={
                    user
                      ? +user.userType === 1
                        ? product.price
                        : product.marketer_price
                      : product.price
                  }
                  image={product.image}
                  image_type={product.image_type}
                  id={product.internal_code}
                />
              </div>
            ))}
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default SearchPage;
