import "./App.css";
import HomePage from "./pages/homePage/homePage";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Router,
  Routes,
} from "react-router-dom";
import Category from "./pages/category/category";
import ProductDetails from "./pages/productDetails/productDetails";
import CheckOut from "./pages/checkOut/checkOut";
import SucceededPage from "./pages/succeededPage/succeededPage";
import SearchPage from "./pages/search/search";
import SearchMobilePage from "./pages/searchMobile/searchMobile";
import PdfComponent from "./components/pdfInvoice/pdfInvoice";
import { useState } from "react";
import { UpdateImageContext } from "./context/updateImage";
import SignIn4 from "./pages/signin/signin4";
import SignUp from "./pages/signup/signup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Policies from "./pages/policies/policies";
import Company from "./pages/company/company";
import MyProfile from "./pages/myProfile/myProfile";
import { QueryClient, QueryClientProvider } from "react-query";
import DefaultLayout from "./layout/DefaultLayout";
import Statistcs2 from "./pages/Dashboard/Statistics";
import Orders from "./components/orders/orders";
import Shipping from "./components/shipping/shipping";

const queryClient = new QueryClient();

function App() {
  const [updateImage, setUpdateImage] = useState(false);

  return (
    <QueryClientProvider client={queryClient}>
      <UpdateImageContext.Provider value={{ updateImage, setUpdateImage }}>
        <div style={{ backgroundColor: "black" }}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/signIn" element={<SignIn4 />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/category/:id" element={<Category />} />
              <Route path="/company/:id" element={<Company />} />
              <Route path="/productDetails/:id" element={<ProductDetails />} />
              <Route path="/checkout" element={<CheckOut />} />
              <Route path="/succeededPage" element={<SucceededPage />} />
              <Route
                path="/search/:category/:productName"
                element={<SearchPage />}
              />
              <Route path="/test" element={<PdfComponent />} />
              <Route path="/policies" element={<Policies />} />

              <Route
                path="/searchMobile/:productName"
                element={<SearchMobilePage />}
              />
              <Route element={<DefaultLayout />}>
                <Route path="/myProfile" element={<Statistcs2 />} />
                <Route path="/orders" element={<Orders />}/>
                <Route path="/shipping" element={<Shipping />}/>
              </Route>
            </Routes>
            <ToastContainer autoClose={5000} />
          </BrowserRouter>
        </div>
      </UpdateImageContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
