import React, { useState } from "react";
import logo from "../../assets/Abdo_Logo.png";
import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { config } from "../../config";

const SignIn4 = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const cookies = new Cookies();
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const response = await axios
        .post(config.baseURL + "Auth/login", user, { withCredentials: "true" })
        .catch((err) => {
          toast.error(err.response.data);
          console.log(err);
        });

      if (response.status === 200) {
        toast.success("تمت العملية بنجاح");
        const user = jwtDecode(response.data.token);

        // console.log(user)

        cookies.set("jwt", response.data.token, {
          expires: new Date(user.exp * 1000),
          secure: true,
        });

        // console.log(cookies.get("jwt"))
        navigate("/");
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <div className="py-16 mt-20 mb-15 bg-white">
      <div
        className="flex bg-white rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl"
        style={{
          justifyContent: "center",
        }}
      >
        {/* Background Image */}
        {/* <div className="hidden lg:block lg:w-1/2 bg-cover ">
        <img src={logo} className="h-15" alt="Adburhman store" />
      </div> */}

        {/* Form Section */}
        <div className="w-full p-8 lg:w-1/2">
          <h2 className="text-2xl font-semibold text-gray-700 text-center">
            تسجيل دخول مسوقين
          </h2>

          {/* phone Input */}
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
              الايميل
            </label>
            <input
              className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none text-right"
              type="email"
              value={user.email}
              onChange={(e) =>
                setUser({
                  ...user,
                  email: e.target.value,
                })
              }
            />
          </div>

          {/* Password Input */}
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
              كلمة المرور
            </label>
            <input
              className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none text-right"
              type="password"
              value={user.password}
              onChange={(e) =>
                setUser({
                  ...user,
                  password: e.target.value,
                })
              }
            />
          </div>

          <p className="mt-3 text-left">نسيت كلمة المرور؟</p>

          <div className="mt-8">
            <button
              className="bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signIn-button"
              onClick={handleSubmit}
            >
              تسجيل دخول
            </button>
          </div>

          {/* Divider */}
          <div className="mt-4 flex items-center justify-between">
            <span className="border-b w-1/5 lg:w-1/4"></span>
            <div className=" text-center text-gray-500 uppercase">أو</div>
            <span className="border-b w-1/5 lg:w-1/4"></span>
          </div>

          <p className="mt-8 text-center text-grey-100">
            لا تملك حساب؟
            <Link
              to="/signup"
              className="text-grey-900 hover:text-grey-700 font-semibold"
            >
              قم بإنشاء حساب
            </Link>
          </p>

          {/* <div className="mt-8">
          <button
            className="bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signIn-button"
            onClick={() => {
              navigate("/signup")
            }}
          >
            إنشاء حساب
          </button>
        </div> */}

          {/* Icon Section with Gray Border */}
          {/* <div className="mt-4 flex justify-center gap-4 p-4">
        
          <div className="flex items-center justify-center h-10 w-10 rounded border border-gray-300">
            <img src={facebookImg} alt="facebook" className=" h-6 w-6" />
          </div>
        
          <div className="flex items-center justify-center h-10 w-10 rounded border border-gray-300 ">
            <img src={appleImg} alt="apple" className=" h-6 w-6" />
          </div>
        
          <div className="flex items-center justify-center h-10 w-10 rounded border border-gray-300 ">
            <img src={googleImg} alt="google" className=" h-6 w-6" />
          </div>
        </div> */}

          {/* Login Button */}
        </div>
      </div>
    </div>
  );
};

export default SignIn4;
