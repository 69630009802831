import React from "react";
import "./secondaryButton.css";

const SecondaryButton = ({ handleClick, text }) => {
  const className = text.includes("حذف") ? "red" : ""
  return (
    <div>
      <button className={`secondaryButton ${className}`} onClick={handleClick}>
        {text}
      </button>
    </div>
  );
};

export default SecondaryButton;
