import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import axios from "axios";
import { config } from "../../config";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";

const Orders = () => {
  const accessToken = Cookies.get("jwt");
  const user = jwtDecode(accessToken);
  const [orders, setOrders] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs(new Date()));
  const [toDate, setToDate] = useState(dayjs(new Date()));
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [searchValue, setSearchValue] = useState("");

  const filteredOrders = orders.filter((order) => {
    if (!searchValue) return true; // If no search value, return all places
    
    const valueToSearch = selectedColumn === "name"
      ? `${order.first_name || ""} ${order.last_name || ""}` // Combine first and last name
      : selectedColumn === "number"
      ? order.phone
      : "";
  
    return valueToSearch.toLowerCase().includes(searchValue.toLowerCase());
  });

  async function handleGetOrders() {
    const response = await axios.get(config.baseURL + "orders/getOrderByUser", {
      params: {
        id: +user.userId,
        fromDate: dayjs(fromDate)
          .hour(0)
          .minute(0)
          .second(0)
          .format("YYYY-MM-DDTHH:mm:ss"),
        toDate: dayjs(toDate)
          .hour(23)
          .minute(59)
          .second(59)
          .format("YYYY-MM-DDTHH:mm:ss"),
      },
    });
    console.log(response.data);
    setOrders(response.data);
  }

  useEffect(() => {
    axios
      .get(config.baseURL + "orders/getOrderByUser", {
        params: {
          id: +user.userId,
          fromDate: dayjs(fromDate)
            .hour(0)
            .minute(0)
            .second(0)
            .format("YYYY-MM-DDTHH:mm:ss"),
          toDate: dayjs(toDate)
            .hour(23)
            .minute(59)
            .second(59)
            .format("YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        setOrders(response.data);
      });
  }, []);

  return (
    <Box sx={{ width: "100%", height: "calc(100vh - 100px)" }}>
      <div
        style={{
          overflowX: "hidden",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",

          alignItems: "center",
          padding: "0 10px",
        }}
      >
        {" "}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              label="From"
              value={fromDate}
              onChange={(newValue) => setFromDate(newValue)}
            />
            <DatePicker
              label="To"
              value={toDate}
              onChange={(newValue) => setToDate(newValue)}
            />
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              size="large"
              onClick={handleGetOrders}
            >
              Send
            </Button>
          </DemoContainer>
        </LocalizationProvider>
      </div>

      {/* <DataGrid
        rows={orders}
        columns={columns}
        autoHeight={false}
        disableColumnResize={false}
        disableSelectionOnClick
        disableColumnMenu
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[5]}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
          },
          "& .MuiDataGrid-cell": {
            wordWrap: "break-word",
            whiteSpace: "normal",
          },
        }}
      /> */}

      <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
        <div className="flex mb-4 space-x-4">
          <select
            className="rounded-md border border-gray-300 px-4 py-2 focus:border-primary focus:ring-primary dark:border-strokedark dark:bg-boxdark dark:text-white"
            value={selectedColumn}
            onChange={(e) => setSelectedColumn(e.target.value)}
          >
            <option value="name">الاسم</option>
            <option value="number">رقم الهاتف</option>
          </select>
          <input
            type="text"
            className="w-full rounded-md border border-gray-300 px-4 py-2 focus:border-primary focus:ring-primary dark:border-strokedark dark:bg-boxdark dark:text-white"
            placeholder="Search..."
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className="max-w-full overflow-x-auto">
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-2 text-left dark:bg-meta-4">
                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                  الاسم
                </th>
                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                  التاريخ
                </th>
                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                  رقم التليفون
                </th>
                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                  العنوان
                </th>
                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                  حالة الاوردر
                </th>
                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                  اجمالى الفاتورة
                </th>
                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                  اجمالى العمولة
                </th>
                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                  تم تسديد الدفعة
                </th>
              </tr>
            </thead>

            <tbody>
              {filteredOrders.map((order, key) => (
                <tr key={key}>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <h5 className="font-medium text-black dark:text-white">
                      {order.first_name} {order.last_name}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">{order.date}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">{order.phone}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {order.address}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">{order.status}</p>
                  </td>

                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {order.finalTotal}
                    </p>
                  </td>

                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {order.commission_total}
                    </p>
                  </td>

                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">
                      {order.marketer_paid ? (
                        <span className="text-green-500">✔️</span> // Green check icon for true
                      ) : (
                        <span className="text-red-500">❌</span> // Red X icon for false
                      )}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};

export default Orders;
